export const lang = {
	'en': {
		1: "HR",
		2: "Manage attendance, leave, missed clock-ins, and business trips easily through the human resources management system. You can submit leave or business trip requests and track your attendance records to ensure transparent and efficient work arrangements.",
		3: "Attendance",
		4: "My attendance",
		5: "Leave application",
		6: "Apply leave",
		7: "Leave history",
		8: "Reimbursement",
		9: "Missed clock-in",
		10: "Rectify missed clock-in",
		11: "Missed clock-ins history",
		12: "Business trip",
		13: "Apply a trip",
		14: "Trip history",
		15: "Finance",
		16: "Financial application",
		17: "Expense application history",
		18: "Submit an expense",
		19: "Submit travel expense",
		20: "Requisition",
		21: "Submit a requisition",
		22: "list of submitted requisition",
		23: "Inventory",
		24: "Souvenirs",
		25: "Souvenirs list",
		26: "Annoucements",
		27: "Reimbursement list",
		28: "Previoius announcements",
		29: "Student Module",
		30: "Students management",
		31: "Students list",
		32: "Students status",
		33: "Overseas students status",
		34: "Students search",
		35: "Payment",
		36: "Refund",
		37: "Travel fund",
		38: "Student Services",
		39: "Survey",
		40: "Accommodations",
		41: "Postgraduate",
		42: "Enquiries",
		43: "Exchange students",
		44: "Academic admin",
		45: "Teachers",
		46: "Foreign teachers",
		47: "Courses",
		48: "Teachers info",
		49: "Cohorts info",
		50: "Courses mapping",
		51: "MCAS",
		52: "Projects",
		53: "Media",
		54: "Admin",
		55: "Admission",
		56: "Teachers",
		57: "Manage teacher tasks, classes, courses, and teacher profile updates through the academic affairs system, ensuring comprehensive and effective academic administration.",
		58: "Manage student information, payments, refund, travel fund, collect survey responses, and handle accommodations through the student services system, ensuring efficient and comprehensive student management.",
		59: "Through the announcement system, you can easily browse and understand all important company updates, policy changes, and other announcements.",
		60: "Efficiently manage assets and souvenirs via the inventory system, with comprehensive details for each item to ensure effective tracking and management.",
		61: "Streamline expense and travel requests, and manage urgent expenses through the financial system, with all records easily accessible for efficient financial management.",
		62: "Partnership Management",
		63: "Through the partnership management system, you can efficiently manage both Global and Chinese partners, projects, and follow-up tasks.",
		64: "Global partners",
		65: "Chinese Partners",
		66: "Projects",
		67: "Global partner profile",
		68: "Faculty contacts",
		69: "Chinese partner Profile",
		70: "Project list",
		71: "Project events",
		72: "System selector",
		73: "Click to access specific systems.",
		74: "Menu list",
		75: "Click to see all available functions.",
		76: "Shortcut",
		77: "Click to access specific function directly",
		78: "Language switch",
		79: "Click to change Chinese version. ",
		80: "Next",
		81: "Prev",
		82: "Skip",
		83: "Done",
		84: "Tutorial Guide",
		85: "Click to view tutorial guide",
		86: "Click to view AEMG knowledge base",
		87: "AEMG Knowledge Base",
		88: "Click to view the yearbook of partner universities",
		89: "University Yearbook",
		90: "Home",
	},
	'zh-cn': {
		1: '人力',
		2: "通过人力资源管理系统轻松管理考勤、请假、未打卡和出差情况。通过简单的在线录入和查看功能，你可以提交请假或出差申请，并追踪自己的考勤记录，确保工作安排的透明和高效。",
		3: "考勤信息",
		4: "我的考勤",
		5: "请假单",
		6: "请假单录入",
		7: '请假单列表',
		8: "报销",
		9: "未打卡单",
		10: "未打卡单录入",
		11: '未打卡单列表',
		12: "出差/公出单",
		13: "公出单录入",
		14: "公出单列表",
		15: "财务",
		16: "事项申请",
		17: "费用及出差申请列表",
		18: "事项申请录入",
		19: "出差申请录入",
		20: "签报",
		21: "签报录入",
		22: "签报列表",
		23: "库存",
		24: "我的物品",
		25: "物品列表",
		26: "公告",
		27: "报销列表",
		28: "公告列表",
		29: "学生",
		30: "学生管理",
		31: "学生列表",
		32: "学生状态",
		33: "学生海外状态",
		34: "学生数据检查",
		35: "学生付款",
		36: "学生退款",
		37: "学生旅行基金申请",
		38: "学生服务",
		39: "调查问卷",
		40: "住宿接机",
		41: "研究生申请",
		42: "咨询列表",
		43: "校际交流学生",
		44: "教务系统",
		45: "教师教务",
		46: "外教工作量统计",
		47: "教学课程安排",
		48: "教师信息",
		49: "班级信息",
		50: "课程匹配",
		51: "MACS学会申请",
		52: "项目管理",
		53: "媒体组",
		54: "管理组",
		55: "录取中心",
		56: "教师",
		57: "通过教务系统管理教师工作、班级、课程和教师资料更新，确保高效及全面的教务管理。",
		58: "通过学生系统管理学生信息、付款、退款处理、旅行基金申请、收集调查问卷以及住宿办理等，确保高效、全面的学生管理。",
		59: "通过公告系统，可以轻松查阅所有重要的公司动态、政策更新以及其他通知等信息。",
		60: "通过库存系统登记和查看你负责的资产及物品，包括各项物品的详细信息，以确保有效的资产追踪和管理。",
		61: "通过财务系统轻松提交费用和出差申请，以及紧急开支的签报。所有申请和签报都能在系统中查看和跟踪，简单快捷。",
		62: "合作关系",
		63: "通过合作关系管理系统，可以轻松查阅维护中外方合作伙伴、相关合作项目以及跟进事项。",
		64: "外方合作",
		65: "中方合作",
		66: "合作项目",
		67: "外方合作伙伴简介",
		68: "联系人",
		69: "中方合作伙伴简介",
		70: "合作项目列表",
		71: "合作项目活动",
		72: "系统选择",
		73: "点击进入相应系统.",
		74: "菜单列表",
		75: "点击查看所有功能",
		76: "快捷按钮",
		77: "点击进入对应的功能页面",
		78: "语言切换",
		79: "点击切换中英文",
		80: "下一步",
		81: "上一步",
		82: "跳过",
		83: "完成",
		84: "教程指南",
		85: "点击查看教程指南",
		86: "点击查看AEMG知识库",
		87: "AEMG知识库",
		88: "点击查看合作方大学年鉴",
		89: "大学年鉴",
		90: "首页",
	}
}