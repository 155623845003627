<template>
	<v-row :key="index">
		<v-container>
			<template v-if="section.title && section.description">
				<v-row>
					<v-col md="8" class="me-auto text-start">
						<h5 class="text-h5 text-typo font-weight-bold mb-2">
							{{ section.title }}
						</h5>
						<p class="text-body">
							{{ section.description }}
						</p>
					</v-col>
				</v-row>
			</template>

			<template v-if="section.cards && section.cards.length > 0">
				<v-row>
					<template v-for="card in section.cards">
						<v-col :key="card.title" lg="4" md="6" cols="12">
							<v-card class="card-shadow border-radius-xl hidden-overflow">
								<div class="px-4 py-4">
									<div class="d-flex">
										<v-avatar size="74"
											class="shadow-xl border-radius-md pa-2"><!-- bg-gradient-default -->
											<v-img :src="card.image" width="50" height="50"></v-img>
										</v-avatar>
										<div class="ms-4 my-auto">
											<h6 class="text-h6 text-typo font-weight-bold mb-2">
												{{ card.title }}
											</h6>
										</div>
										<v-menu transition="slide-y-transition" offset-y offset-x min-width="150">
											<template v-slot:activator="{ on, attrs }">
												<v-btn icon :ripple="false" class="text-secondary ms-auto mt-3"
													v-bind="attrs" v-on="on" small v-if="card.menuButtons.length > 0">
													<v-icon class="guide-2" size="16">fas fa-ellipsis-v</v-icon>
												</v-btn>
											</template>

											<v-list class="py-0">
												<v-list-item v-for="(button, buttonIndex) in card.menuButtons"
													:key="buttonIndex" class="list-item-hover-active"
													@click="handleButtonClick(button)">
													<v-list-item-content class="pa-0">
														<v-list-item-title class="ls-0 text-body font-weight-600 mb-0">
															{{ button.label }}
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
												<hr class="horizontal dark" v-if="card.menuButtons.length > 4" />
											</v-list>
										</v-menu>
									</div>
									<p class="text-sm mt-4 text-body">
										{{ card.description }}
									</p>
									<span class="guide-3 avatar-group d-flex mt-2">
										<v-btn v-for="(button, buttonIndex) in card.menuButtons.slice(0,3)"
											:key="buttonIndex" small color="gradient-default" dark class="mr-2 unset-transform"
											@click="handleButtonClick(button)">
											{{ button.label }}
										</v-btn>
										<v-menu v-if="card.menuButtons.length > 4" v-model="menu"
											:close-on-content-click="false" :nudge-right="40"
											transition="scale-transition" offset-y min-width="150px">
											<template v-slot:activator="{ on, attrs }">
												<v-btn icon :ripple="false" v-bind="attrs" v-on="on" small>
													<v-icon>mdi-dots-vertical</v-icon>
												</v-btn>
											</template>

											<v-list>
												<v-list-item v-for="(
                            button, buttonIndex
                          ) in card.menuButtons.slice(4)" :key="buttonIndex" @click="handleButtonClick(button)">
													<v-list-item-title>{{
                            button.label
                          }}</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</span>
								</div>
							</v-card>
						</v-col>
					</template>
				</v-row>
			</template>
		</v-container>
	</v-row>
</template>

<script>
	export default {
		props: {
			section: Object,
			index: Number,
			menu: {},
		},
		methods: {
			handleButtonClick(button) {
				if (button.url) {
					window.open(button.url, '_blank');
				}
			}
		}
	};
</script>
<style lang="scss">
	.theme--dark.v-btn.v-btn--has-bg {
		background-color: #0074c0 !important;
	}

	.v-btn--is-elevated {
		box-shadow: none !important;
	}

	.hidden-overflow {
		overflow: hidden;
	}

	.unset-transform {
		text-transform: unset !important;
	}
</style>