<template>
	<!-- <router-view></router-view> -->
	<NewDashboard />
</template>

<script>
	// 引入 NewDashboard 组件
	import NewDashboard from './views/Layout/DashboardLayout.vue';
	export default {
		name: 'App',
		components: {
			// 在 components 对象中注册 NewDashboard，使其可以在模板中使用
			NewDashboard
		}
	}
</script>